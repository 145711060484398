import React from "react";
import { graphql } from "gatsby";

import { CategoryT } from "@faq/types";

import { toArticle, toCategory } from "@faq/utils/routes";
import { truncate } from "@faq/utils/articles";

import Layout from "@faq/components/Layout";
import Block from "@faq/components/Block";

import * as s  from "./Category.module.css";

type CategoryPageProps = {
  data: {
    strapiCategory: CategoryT;
  };
};

const Category: React.FC<CategoryPageProps> = ({
  data: { strapiCategory },
}) => {
  const { title, description, articles, slug } = strapiCategory;

  return (
    <Layout
      seo={{
        pageTitle: title,
        pageDescription: description,
        pageUrl: toCategory(slug),
      }}
      breadcrumb={[{ label: title }]}
    >
      <h1 className={s.title}>{title}</h1>
      <div className={s.subTitle}>{description}</div>
      <div className={s.counter}>
        {articles.length} article{`${articles.length > 1 ? "s" : ""}`}
      </div>
      <div>
        {articles?.map(({ slug, title, description, category }, i) => (
          <Block
            key={i}
            to={toArticle(slug, category.slug)}
            title={title}
            content={truncate(description.replace(/\*/g, ""), 300, "...")}
          />
        ))}
      </div>
    </Layout>
  );
};

export default Category;

export const query = graphql`
  query CategoryQuery($id: Int!) {
    strapiCategory(strapiId: { eq: $id }) {
      title
      description
      slug
      articles {
        slug
        title
        description
        category {
          slug
        }
      }
    }
  }
`;
